.legend-css::-webkit-scrollbar {
    width: 20px;
  }
.legend-css::-webkit-scrollbar-track {

    margin: 10px 0px;
    
}

.legend-css::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #EFEBEB;
  }