.input{
    animation: input-error;
    animation-duration: 0.5s;
    transition: transform 0.5s;
}

/* .MuiInputBase-root.Mui-error  {
    animation: input-error;
    animation-duration: 0.5s;
    transition: transform 0.5s;
}  */

@keyframes input-error {
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0px);
    }
}
.image {
    background-image: url('../../assets/login-img.svg');
    background-position: top;
}


